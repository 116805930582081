/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.pricing {
  color: rgb(255, 160, 0);
  font-size: 18px;
  font-weight: 500;
}
